import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ title }) => {
  return (
    <Helmet
      htmlAttributes={{ lang: "pl" }}
      title={`Higienistka Magdalena Romatowska | ${title}`}
      meta={[
        {
          name: `description`,
          content: `Dyplomowana higienistka stomatologiczna Magdalena Romatowska. Skaling, piaskowanie, fluoryzacja, instruktaż higieny, zdjęcia przed i po higienizacji. `,
        },
      ]}
    >
      <link
        rel="canonical"
        href="https://higienistkamagdalena.pl/"
        title="Higienistka Magdalena Romatowska"
      ></link>
    </Helmet>
  )
}

export default SEO
